import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout"
import Seo from "@components/seo"
import JazzCaresForXywavComponent from "@components/jazzcaresForXywav"

const JazzCaresForXywavNarcPage = ({ location, data }) => {
  const videoMetaData = [
    {
      metadata: {
        name: "Watch a JazzCares® Nurse Case Manager walk you through all JazzCares has to offer | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about enrolling in JazzCares® once you are prescribed XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.thumbImage.publicURL,
        uploadDate: "2023-11-13",
        contentUrl: `https://www.xywav.com/getting-xywav/?wvideo=wc6ibtt719`,
        duration: "PT6M7S",
      },
    },
  ]

  return (
    <Layout
      location={location}
      headerType="narcolepsy"
      footerType="narcolepsy"
      exitModalColour="red"
      copyright={
        <>
          &copy; 2023 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <nobr>US-XYW-2200416 Rev1123</nobr>
        </>
      }
    >
      <Seo
        title="JazzCares® & Cost Savings | XYWAV®"
        description="Get support and resources with JazzCares® for XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
        canonicalLocation="/jazzcares-for-xywav/"
        videoMetadata={videoMetaData}
      />
      <JazzCaresForXywavComponent theme="narcolepsy" videoThumnails={data} />
    </Layout>
  )
}

export default JazzCaresForXywavNarcPage

export const query = graphql`
  query {
    thumbImage: file(
      relativePath: { eq: "video-thumbnails/jazzcare-nurse-4.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
